import { 
  actionTypes,
  RepertoireData,
  UserRepertoireProgress,
  SetRepertoireSelectPayload,
  RepertoireState,
} from 'Types/RepertoireTypes'
import { 
  AuthenticationToken
} from '../Types'

export const updateRepertoireDataRequest = (payload: UserRepertoireProgress & AuthenticationToken) => ({
  type: actionTypes.UPDATE_REPERTOIRE_DATA_REQUEST,
  payload
})

export const fetchRepertoireDataSuccess = (payload: RepertoireData[]) => ({
  type: actionTypes.FETCH_REPERTOIRE_DATA_SUCCESS,
  payload
})

export const setRepertoireEnded = (payload: boolean) => ({
  type: actionTypes.SET_REPERTOIRE_ENDED,
  payload
})

export const setRepertoireSelect = (payload: SetRepertoireSelectPayload) => ({
  type: actionTypes.SET_REPERTOIRE_SELECT,
  payload
})


export const fetchURPSuccess = (payload: UserRepertoireProgress[]) => ({
  type: actionTypes.FETCH_URP_SUCCESS,
  payload
})

export const loadRepertoireState = (payload: RepertoireState) => ({
  type: actionTypes.LOAD_REPERTOIRE_STATE,
  payload
})
