import { Box } from '@mui/material';
import { useEffect, useRef, FunctionComponent } from 'react'
import { Menu } from 'Components/RoadmapComponents/Menu';
import { useSelector } from 'react-redux'
import { MainAppReducer } from 'Types';
import Survey from 'Containers/Roadmap/Survey';
import Home from './Home';
import Browse from './Browse';
import { RepertoireReducer } from 'Types/RepertoireTypes';

interface RepertoireProps {
  repPageSelected: string;
  setRepPageSelected: (page: string) => void;
}

const Repertoire: FunctionComponent<RepertoireProps> = ({repPageSelected, setRepPageSelected}) => {

  const repRef = useRef<HTMLImageElement>(null);

  const data = useSelector((state: RepertoireReducer) => state.repertoireReducer)
  const repertoireData = data.repertoireData

  const appData = useSelector((state: MainAppReducer) => state.mainAppReducer)
  // console.log("appData?", appData)
  
  // Remove this for now; don't have roadmap scroll issue, so should be fine.
  // This bit is the key for scrolling in a vertical scroll container, but disabling the swipe back!
  // useEffect(() => {
  //   const preventNavigation = (event: WheelEvent) => {
  //     if (repRef.current) {
  //       // Check if the horizontal scroll is negative and >= than vertical, prevent (no swipe back!)
  //       if (event.deltaX < 0 && Math.abs(event.deltaX) >= Math.abs(event.deltaY)) {
  //         event.preventDefault();
  //       }
  //     }
  //   };
  //   const element = repRef.current;
  //   element?.addEventListener('wheel', preventNavigation, { passive: false });
  //   return () => {
  //     element?.removeEventListener('wheel', preventNavigation);
  //   };
  // }, []);

  return (
    <Box 
      sx={{
        display: 'flex', 
        // flexDirection: 'column',
        height: '100vh', 
        width: '100%',
      }} ref={repRef}
    >
      <Menu selected={"Repertoire"}/>
      <Survey />

      <Box sx={{display: 'flex', flexDirection: 'column', width: 'calc(100% - 100px)',}}>

        <Box sx={{backgroundColor: '#040B13', height: '60px', display: 'flex'}}>
          <Box sx={{marginLeft: '16px', display:'flex', gap: '16px',}}>
              <Box sx={ (repPageSelected=="Home") ? {...selectStyles, ...selectSelectedStyles} : selectStyles} onClick={() => setRepPageSelected("Home")}>
                <Box sx={textStyles}>Home</Box>
              </Box>
            <Box sx={ (repPageSelected=="Browse") ? {...selectStyles, ...selectSelectedStyles} : selectStyles} onClick={() => setRepPageSelected("Browse")}>
              <Box sx={textStyles}>Browse</Box>
            </Box>
          </Box>
        </Box>



        {/* <Box sx={{width: 'calc(100% - 100px)', backgroundColor: '#040B13', height: '60px', display: 'flex'}}></Box> */}
        { repPageSelected == "Home" && <Home/> }
        { repPageSelected == "Browse" && <Browse/> }


      </Box>

      
    </Box>
  )

}

export default Repertoire



const selectStyles = {
  
  color: 'white',
  margin: '14px 5px 5px 5px',
  padding: '0px 32px',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    background: "rgb(202,124,98,.25)",
    borderRadius: '5px',
    cursor: 'pointer',
    transition: '0.3s',
    boxShadow: '0px 0px 5px 5px rgb(202,124,98,.25)',
    },
}

const textStyles = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '15px',
  lineHeight: '24px',
  letterSpacing: '1px',
}

const selectSelectedStyles = {
  borderBottom: '2px solid', 
  borderColor: '#6F87F1',
  color: '#6F87F1',
}
