import { Box } from '@mui/material';
import RepertoireHamburger from '../../assets/images/Repertoire/RepertoireHamburger.png';
import RepertoireLeftArrow from '../../assets/images/Repertoire/RepertoireLeftArrow.png';
import RepertoireCheck from '../../assets/images/Repertoire/RepertoireCheck.png';
import TestRepPreview1 from '../../assets/images/Repertoire/TestRepPreview1.png';
import TestRepPreview2 from '../../assets/images/Repertoire/TestRepPreview2.png';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as repertoireActions from '../../Actions/repertoire'
import { useSelector } from 'react-redux'
import { RepertoireReducer } from 'Types/RepertoireTypes';
import { LegendToggle } from '@mui/icons-material';
import { REPERTOIRE_PROGRESS } from 'Types/RepertoireTypes';
import { usePreventRepClick } from './DragScroll';

const RepertoireItem = (props: any ) => {
  const preventRepClick = usePreventRepClick(); 
  const { repertoireGroup, repertoireData, containerRef } = props
  const [ showDetails, setShowDetails ] = useState(false)
  const hamburgerRef =useRef<HTMLImageElement>(null);
  const songDetailsRef = useRef<HTMLImageElement>(null);

  const repertoireReducerData = useSelector((state: RepertoireReducer) => state.repertoireReducer)
  const userRepertoireProgress = repertoireReducerData.userRepertoireProgresses
  const selectUserRepertoireProgress = userRepertoireProgress.find(item => item.repertoire.id === repertoireData.id)
  // console.log(repertoireData.id, selectUserRepertoireProgress)
  let selectRepertoireProgressPct = 0
  if (selectUserRepertoireProgress && selectUserRepertoireProgress.percent_complete) {
    selectRepertoireProgressPct = selectUserRepertoireProgress.percent_complete
  }


  let currentRepState;
  if (selectRepertoireProgressPct >= 100) {
    currentRepState = REPERTOIRE_PROGRESS.COMPLETE
  } else if (selectRepertoireProgressPct > 0) {
    currentRepState = REPERTOIRE_PROGRESS.IN_PROGRESS
  } else {
    currentRepState = REPERTOIRE_PROGRESS.NOT_STARTED
  }

  const navigate = useNavigate();

  const getPosition = () => {
    if (hamburgerRef.current) {
      const rect = hamburgerRef.current.getBoundingClientRect();
      return { x: rect.x, y: rect.y };
    }
    return { x: 0, y: 0}
  };

  const dispatch = useDispatch()


  // const title = "Somebody To Love"
  // console.log("repertoireData??", repertoireData)
  const repertoireId = repertoireData.id
  const title = repertoireData.name
  // const composer = "M. Mussorgsky"
  let composer = ""
  repertoireData.composers.forEach((comp: any, idx: number) => {
    composer = composer.concat(idx > 0 ? `, ${comp.name}`: comp.name)
  })
  let genre = ""
  repertoireData.genres.forEach((gen: any, idx: number) => {
    genre = genre.concat(idx > 0 ? `, ${gen.name}`: gen.name)
  })
  // const level = 4
  const level = repertoireData.level.level_number
  const bpm = repertoireData.bpm

  // replace "TestRepPreview1" with url:
  const previewSrc = repertoireData.repertoire_png_preview_url//TestRepPreview1 //repertoireData.repertoire_png_preview_url

  const repertoireDetails: RepertoireDetails = {
    Name: title,
    Composer: composer,
    Level: level,
    Genre: genre,
    // Duration: '1:20',
    BPM: bpm,
  }

  let bodyColor;
  let levelColor;
  let borderColor;
  // if (repertoireGroup == 'recent-played') {
  //   bodyColor = '#2C5950'//'#469F6A'
  //   levelColor = '#2E5B3E'
  //   borderColor = '#469F6A'
  // } else if (repertoireGroup == 'in-progress') {
  //   bodyColor = '#2F416E'//'#6F87F1'
  //   levelColor = '#31353F'
  //   borderColor = '#6F87F1'
  // } else {
  //   bodyColor = '#07111D'
  //   levelColor = '#31353F'
  //   borderColor = '#767C8C'
  // }
  if (currentRepState == REPERTOIRE_PROGRESS.COMPLETE) {
    bodyColor = '#2C5950'//'#469F6A'
    levelColor = '#2E5B3E'
    borderColor = '#469F6A'
  } else if (currentRepState == REPERTOIRE_PROGRESS.IN_PROGRESS) {
    bodyColor = '#2F416E'//'#6F87F1'
    levelColor = '#31353F'
    borderColor = '#6F87F1'
  } else {
    bodyColor = '#07111D'
    levelColor = '#31353F'
    borderColor = '#767C8C'
  }

  useEffect(() => {
    // This function checks if the click is outside the modal
    function handleClickOutside(event: any) {
      if (songDetailsRef.current && !songDetailsRef.current.contains(event.target)) {
        setShowDetails(false);
      }
    }
  
    // Add event listener when the component is mounted
    document.addEventListener('mousedown', handleClickOutside);
  
    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [songDetailsRef, setShowDetails]); // Only re-run if detailsRef or setShowDetails changes
  

  return (
    <Box sx={{
      marginBottom: '20px', marginTop: '14px', minWidth: '160px', maxWidth: '160px', height: '180px', backgroundColor: bodyColor, borderRadius: '6px', border: `2px solid ${borderColor}`, position: 'relative', 
      '&:hover': {
        cursor: showDetails ? 'unset':'pointer',
        },
      }}
      onClick={() => {
        if (!showDetails && !preventRepClick) {
          dispatch(repertoireActions.setRepertoireSelect(repertoireId));
          navigate('/repertoire-play')
        }
      }}
    >
      { showDetails && <SongDetails repertoireDetails={repertoireDetails} setShowDetails={setShowDetails} getPosition={getPosition} songDetailsRef={songDetailsRef} containerRef={containerRef}/>}
      <Box sx={{width: '100%', height: '100px', backgroundColor: 'white', position: 'relative', borderTopLeftRadius: '4px', borderTopRightRadius: '4px', borderBottom: `2px solid ${borderColor}`}}>
      <Box sx={{paddingTop: '0px', height: '100px', overflow: 'hidden', marginLeft: '0px', marginRight: '0px', borderTopLeftRadius: '4px', borderTopRightRadius: '4px', }}>
        <img style={{width: '100%'}} src={previewSrc}/>
      </Box>

        { currentRepState == REPERTOIRE_PROGRESS.IN_PROGRESS && 
          <Box sx={{
            position: 'absolute', 
            left: '7px',
            top: '7px',
            backgroundColor: '#6F87F1',
            borderRadius: '10px',
            color: 'white',
            fontSize: '10px',
            padding: '4px 6px',
            fontWeight: '500',
          }}>{selectRepertoireProgressPct && Math.floor(selectRepertoireProgressPct)}% COMPLETE</Box>
        }
        { currentRepState == REPERTOIRE_PROGRESS.COMPLETE && 
          <Box sx={{
            position: 'absolute', 
            left: '6px',
            top: '6px',
          }}><img style={{width: '22px'}} src={RepertoireCheck}/></Box>
        }

        <Box sx={{...levelStyles, ...{backgroundColor: levelColor}}}>LVL {level}</Box>
        <Box sx={selectStyles} onClick={(e) => {
          e.stopPropagation()
          setShowDetails(true)
        }}>
          <img ref={hamburgerRef} style={{marginTop: '0px', width: '15px'}} src={RepertoireHamburger}/>
        </Box>
      </Box>
      
      <Box sx={{height: '74px', paddingBottom: '6px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingLeft: '8px'}}>
        
        <Box sx={titleStyles}>{title}</Box>
        <Box sx={composerStyles}>{composer}</Box>

      </Box>
      
        
    </Box>
  )

}
export default RepertoireItem


const titleStyles = {
  marginTop: '5px',
  marginBottom: '7px',
  color: '#FFFFFF',
  fontFamily: 'Lato',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '17px',
  letterSpacing: '0.01em',
  textAlign: 'left',
}

const composerStyles = {
  marginBottom: '2px',
  color: '#FFFFFF',
  fontFamily: 'Lato',
  fontSize: '14px',
  fontStyle: 'italic',
  fontWeight: '700',
  lineHeight: '17px',
  letterSpacing: '0.01em',
  textAlign: 'left',
}

const levelStyles = {
  position: 'absolute',
  left: '6px',
  bottom: '6px',
  borderRadius: '4px',
  padding: '0px 8px',

  color: '#FFFFFF',
  fontFamily: 'Lato',
  fontSize: '12px',
  fontWeight: '700',
  lineHeight: '24px',
  letterSpacing: '1px',
  textAlign: 'left',

}

const selectStyles = {
  position: 'absolute',
  right: '4px',
  top: '4px', 
  width: '24px',
  height: '24px',
  backgroundColor: '#CED0D4',
  borderRadius: '50%',
  display: 'flex', alignItems: 'center', justifyContent: 'center',
  '&:hover': {
    // background: "rgb(202,124,98,.25)",
    // borderRadius: '5px',
    cursor: 'pointer',
    // transition: '0.3s',
    // boxShadow: '0px 0px 5px 5px rgb(202,124,98,.25)',
    },
}

interface RepertoireDetails {
  Name: string;
  Composer: string;
  Level: string;
  Genre: string;
  // Duration: string;
  BPM: string;
}


const SongDetails = (props: any) => {
  const { 
    repertoireDetails,
    setShowDetails,
    getPosition,
    songDetailsRef,
    containerRef,
  } = props 

  const [modalPosition, setModalPosition] = useState({ left: '0px', top: '0px' });

  useEffect(() => {
    const updatePosition = () => {
      const pos = getPosition();
      setModalPosition({
        left: `${pos.x - 50}px`,
        top: `${pos.y + 36}px`,
      });
    };
    updatePosition()

    const handleScroll = () => {
      updatePosition()
    };
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };

  }, []);

  return (
    <Box sx={{
      position: 'fixed',
      // position: 'absolute',
      zIndex: 10, // Ensure it's above other content
      ...modalPosition,
      minWidth: '350px', // Adjust as needed
      borderRadius: '8px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
      padding: '16px',
      // border: '1px solid #ccc',
      backgroundColor: '#050B13',
    }} ref={songDetailsRef}>
      <Box sx={{
        position: 'absolute',
        top: '-10px', // Half of the carot size to move it above the modal
        left: '40px', // Align this with the hamburger icon
        width: '0',
        height: '0',
        borderLeft: '16px solid transparent',
        borderRight: '16px solid transparent',
        borderBottom: '16px solid #050B13', // Carot color should match modal background
      }} />
      
      <Box sx={detailsTitleTextStyles}>
        
        <Box sx={{textAlign: 'center', marginTop: '6px', marginBottom: '16px', position: 'relative'}}>
          <Box sx={{
            position: 'absolute',
            height: '100%', 
            top: '0px',
            left: '8px',
            cursor: 'pointer',
            display: 'flex', justifyContent: 'center',
          }} onClick={(e) => {
            e.stopPropagation()
            setShowDetails(false)
          }}>
            <img style={{width: '16px', margin: 'auto'}} src={RepertoireLeftArrow} />
          </Box>
          SONG DETAILS
        </Box>
        <Box sx={detailsTextStyles}>
          {Object.keys(repertoireDetails).map((k: string) => {
            const key = k as keyof RepertoireDetails;
            return (
              <Box sx={{display: 'flex', borderBottom: '1px solid #31353F', padding: '20px 14px', margin: '2px 0px'}}>
                <Box sx={{width: '35%',}}>{k}</Box>
                <Box sx={{width: '65%', color: '#ADB1B7'}}>{repertoireDetails[key]}</Box>
              </Box>
            )
          })}
        </Box>
       
      </Box>
    </Box>
  )
}

const detailsTitleTextStyles = {
  color: '#FFFFFF',
  fontFamily: 'Lato',
  fontSize: '15px',
  fontHeight: '700',
  lineHeight: '24px',
  letterSpacing: '1px',
  textAlign: 'left',
}

const detailsTextStyles = {
  display: 'flex', 
  flexDirection: 'column',
  color: '#ECECEC',
  position: 'relative',
  fontFamily: 'Lato',
  fontSize: '14px',
  fontHeight: '400',
  lineHeight: '16.8px',
  letterSpacing: '1px',
  textAlign: 'left',
}

const detailsContainerStyles = {
  
}