import { Box, TextField } from '@mui/material';
import RepertoirePlayContainer from './RepertoirePlayContainer';
import RepBackArrow from '../../assets/images/Repertoire/RepBackArrow.png'
import TempoIcon from '../../assets/images/Repertoire/TempoIcon.png'
import AccuracyMusicIcon from '../../assets/images/Repertoire/AccuracyMusicIcon.png'
import PerformanceIcon from '../../assets/images/Repertoire/PerformanceIcon.png'
import TempoPlus from '../../assets/images/Repertoire/TempoPlus.png'
import TempoMinus from '../../assets/images/Repertoire/TempoMinus.png'
import HandActive from '../../assets/images/Repertoire/HandActive.png'
import HandInactive from '../../assets/images/Repertoire/HandInactive.png'
import Reset from '../../assets/images/Repertoire/Reset.svg'
import Restart from '../../assets/images/Repertoire/Restart.png'
import RestartBig from '../../assets/images/Repertoire/RestartBig.png'
import Play from '../../assets/images/Play.png'
import Pause from '../../assets/images/Pause.png'
import { useDispatch, useSelector } from 'react-redux'
import * as appActions from '../../Actions/app'
import * as repertoireActions from '../../Actions/repertoire'
import { MainAppReducer } from 'Types';
import { RepertoireReducer } from 'Types/RepertoireTypes';
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useCallback, useRef } from 'react';
import { useAuthenticationContext } from 'Contexts/Authentication';
import { useRepertoirePlayContext } from 'Contexts/RepertoirePlayContext';
import Fullscreen from 'Components/Fullscreen';
import { find } from 'lodash';
import { CustomSwitch } from "Components";
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { AuthReducer } from 'Types/AuthTypes';

interface StyledSliderProps {
  handleSliderChange: any;
  performanceTempo: number;
  sliderValue: number;
  setSliderValue: (value: number) => void;
  tempPctAdjustment: number;
  setTempPctAdjustment: (value: number) => void;
}

const MAX_TEMPO_PCT = 3.00 // 300%
const MIN_TEMPO_PCT = .10 // 10%

const StyledSlider: React.FC<StyledSliderProps> = ({handleSliderChange, performanceTempo, sliderValue, setSliderValue, tempPctAdjustment, setTempPctAdjustment,}) => {
  // This maps for the sliding scale to be 10%-300% but middle still be 100% 
  const sliderStyle = {
    color: '#6F87F1',
    '& .MuiSlider-track': {
      color: '#2D3A67',
    },
    '& .MuiSlider-rail': {
      color: '#2D3A67',
    }
  };
  return (
    <Box sx={{textAlign: 'center', margin: '20px 30px 10px 30px'}}>
      <Slider
        sx={sliderStyle}
        value={sliderValue}
        onChange={handleSliderChange}
        min={0}
        max={100}
        aria-labelledby="continuous-slider"
        valueLabelFormat={(x) => `${Math.round(sliderValue)}%`}
      />
      <Typography>
        {`${Math.round(tempPctAdjustment)}%`}
      </Typography>
    </Box>
  );
}

const RepertoirePlay = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const {user} = useAuthenticationContext();
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const repertoireReducerData = useSelector((state: RepertoireReducer) => state.repertoireReducer)
  const repertoireData = repertoireReducerData.repertoireData
  const repertoireIdSelect = repertoireReducerData.repertoireIdSelect
  const tempo = data.tempo
  const repertoireSelected = find(repertoireData, rd => rd.id === repertoireIdSelect)
  let levelSelect = data.levelSelect
  const [showTierComplete, setShowTierComplete] = useState(false)
  const [showUnitComplete, setShowUnitComplete] = useState(false)
  const [inPerformanceMode, setInPerformanceMode] = useState(false)
  const [headerVisible, setHeaderVisible] = useState(true); // New state for header visibility
  const [sliderValue, setSliderValue] = useState(50)
  const [tempPctAdjustment, setTempPctAdjustment] = useState(100);
  const [ showDetails, setShowDetails ] = useState(false);
  const [ showAccuracyDetails, setShowAccuracyDetails ] = useState(false);

  const togglePerformanceMode = () => {
    setInPerformanceMode(prev => {
      const newMode = !prev;
      setHeaderVisible(!newMode);
      return newMode;
    });
  };

  useEffect(() => {
    if (inPerformanceMode) {
      const handleMouseMove = (e: MouseEvent) => {
        if (e.clientY < 50) {
          setHeaderVisible(true);
        } else if (e.clientY > 120) {
          setHeaderVisible(false);
        }
      };
      window.addEventListener('mousemove', handleMouseMove);
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, [inPerformanceMode]);

  const [ showAccuracy, setShowAccuracy ] = useState(false);
  const [cursorIsVisible, setCursorIsVisible] = useState(true);
  const [manualTempoInput, setManualTempoInput] = useState(tempo.toString());
  const tempoInputRef = useRef<HTMLInputElement>(null);
  
  const {
    allowChevrons, 
    accuracyPercent,
    setAccuracy, 
    setAllowChevrons, 
    showCursorSelector, 
    setShowCursorSelector,
    performanceTempo, setPerformanceTempo, 
    errorRecognitionActive, setErrorRecognitionActive
  } = useRepertoirePlayContext();

  const max_tempo_value = performanceTempo != null ? performanceTempo*MAX_TEMPO_PCT : 0
  const min_tempo_value = performanceTempo != null ? performanceTempo*MIN_TEMPO_PCT : 9999
  
  // Need to prevent space (play) from scrolling down lol.
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.code === 'Space') {
        event.preventDefault();
      }
      else if (event.key === 'Enter') {
        if (tempoInputRef.current) {
          tempoInputRef.current.blur();
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const setManualTempoChange = () => {
    let value = parseInt(manualTempoInput, 10)
    if (!isNaN(value)) {
      if (value > max_tempo_value) {
        value = max_tempo_value
      } else if (value < min_tempo_value) {
        value = min_tempo_value
      }
      mapTempoToSlider(value);
    }
    setManualTempoInput(tempo.toString())
  }

  const mapTempoToSlider = (newTempo: number) => {
    if (performanceTempo != null) {
      let newTempPctAdjustment = 100*newTempo/performanceTempo // DO not round yet otherwise will not trigger change in tempo (since based on percentage). Only round in display.
      let newValue = (newTempPctAdjustment - 100)*(100/2)/(2*100) + 100/2
      if (newValue <= 50) {
        newValue = ((newTempPctAdjustment - 10)/90)*(100/2)
      }
      setSliderValue(newValue)
      setTempPctAdjustment(newTempPctAdjustment)
    }
  }

  useEffect(() => {
    if (repertoireSelected?.bpm) {
      let perfTempo = repertoireSelected.bpm > 0 ? repertoireSelected.bpm : 120
      setPerformanceTempo(perfTempo)
      dispatch(appActions.setTempo({tempo: perfTempo})) // Start with tempo at performance tempo...
    }
  }, [repertoireSelected])

  useEffect(() => {
    if (performanceTempo) {
      dispatch(appActions.setTempo({tempo: Math.round(performanceTempo*tempPctAdjustment/100.0)}))
    }
  }, [performanceTempo, tempPctAdjustment])

  const [scrollPosition, setScrollPosition] = useState(null);
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (containerRef.current && scrollPosition != null) {
      // setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scrollTo({top: scrollPosition, behavior: 'smooth'});
        }
      // }, 2000)
    }
  }, [scrollPosition, containerRef.current])

  if (!repertoireSelected) {
    return (<></>)
  }

  return (
    <Box sx = {{display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: 'white',}}>
      <Box sx={{position: 'fixed', width: '100%', zIndex: 2}}>
      <Header 
        showAccuracy={showAccuracy} 
        accuracyPercent={accuracyPercent} 
        levelSelect={levelSelect} 
        phrasesTilNextTier={data.phrasesTilNextTier} 
        allowChevrons={allowChevrons}
        showCursorSelector={showCursorSelector}
        setShowCursorSelector={setShowCursorSelector}
        headerVisible={headerVisible}
        togglePerformanceMode={togglePerformanceMode}
        inPerformanceMode={inPerformanceMode}
        tempo={tempo}
        performanceTempo={performanceTempo}
        sliderValue={sliderValue}
        setSliderValue={setSliderValue}
        tempPctAdjustment={tempPctAdjustment}
        setTempPctAdjustment={setTempPctAdjustment}
        showDetails={showDetails} setShowDetails={setShowDetails}
        showAccuracyDetails={showAccuracyDetails} setShowAccuracyDetails={setShowAccuracyDetails}
        errorRecognitionActive={errorRecognitionActive} setErrorRecognitionActive={setErrorRecognitionActive}
        manualTempoInput={manualTempoInput} setManualTempoInput={setManualTempoInput} setManualTempoChange={setManualTempoChange} mapTempoToSlider={mapTempoToSlider} tempoInputRef={tempoInputRef} max_tempo_value={max_tempo_value} min_tempo_value={min_tempo_value}
      />
      </Box>
      <Box className="main-app-container-styles"
        sx={{
          display: 'flex', flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          paddingTop: '0px',//'170px', // since making header fixed position
          overflowY: 'auto',
          overflowX: 'hidden',
        }} ref={containerRef} 
      >
        <Box sx={{marginTop: '180px', width: '1000px', maxWidth: '90%', display: 'flex', flexDirection: 'column', gap: '30px'}}>
          <Box sx={songTitleStyles}>
            {repertoireSelected && repertoireSelected.name}
          </Box>
          <Box sx={composerStyles}>
            <>
              {repertoireSelected && repertoireSelected.composers && repertoireSelected.composers[0].name}
            </>
          </Box>
        </Box>
        <RepertoirePlayContainer 
          showKeyboard={false} 
          setAccuracy={setAccuracy} 
          setShowAccuracy={setShowAccuracy} 
          setAllowChevrons={setAllowChevrons} 
          cursorIsVisible={cursorIsVisible}
          scrollPosition={scrollPosition}
          setScrollPosition={setScrollPosition}
        />
      </Box>
      <FooterContainer cursorIsVisible={cursorIsVisible} setCursorIsVisible={setCursorIsVisible} headerVisible={headerVisible}/>
    </Box>
  )
}

const accuracyColors = [
  '#00C2FF', //neutral
  '#40E16D', // green
  '#EBC334', // yellow
  '#ff7d6f',
  // 'rgba(200, 128, 114, 1.0)',// '#EB3459', // red
]

interface HeaderProps {
  levelSelect: number;
  accuracyPercent: number | null | undefined;
  showAccuracy: boolean;
  phrasesTilNextTier: number;
  allowChevrons: boolean;
  showCursorSelector: any;
  setShowCursorSelector: any;
  headerVisible: any;
  togglePerformanceMode: any;
  inPerformanceMode: boolean;
  tempo: number;
  performanceTempo: number | null;
  sliderValue: number;
  setSliderValue: any;
  tempPctAdjustment: any;
  setTempPctAdjustment: any;
  showDetails: any;
  setShowDetails: any;
  showAccuracyDetails: any;
  setShowAccuracyDetails: any;
  errorRecognitionActive: any;
  setErrorRecognitionActive: any;
  manualTempoInput: any;
  setManualTempoInput: any;
  setManualTempoChange: any;
  mapTempoToSlider: any;
  tempoInputRef: any;
  max_tempo_value: any;
  min_tempo_value: any;
}

const Header = (props: HeaderProps) => {
  const {
    accuracyPercent, 
    headerVisible,
    togglePerformanceMode,
    inPerformanceMode,
    tempo,
    performanceTempo,
    sliderValue, setSliderValue,
    tempPctAdjustment, setTempPctAdjustment,
    showDetails, setShowDetails,
    showAccuracyDetails, setShowAccuracyDetails,
    errorRecognitionActive, setErrorRecognitionActive,
    manualTempoInput, setManualTempoInput, setManualTempoChange, mapTempoToSlider, tempoInputRef, max_tempo_value, min_tempo_value
  } = props

  const tempoContainerRef = useRef<HTMLImageElement>(null);
  const accuracyContainerRef = useRef<HTMLImageElement>(null);
  
  
  const [isSmallScreen, setIsSmallScreen] = useState(false)//useState(window.innerWidth <= smallScreenBreakWidth || window.innerHeight <= smallScreenBreakHeight);
  useEffect(() => {
    const handleResize = () => { setIsSmallScreen(false)}//window.innerWidth <= smallScreenBreakWidth || window.innerHeight <= smallScreenBreakHeight); }
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize); };
  }, []);

  useEffect(() => {
    // This function checks if the click is outside the modal
    function handleClickOutside(event: any) {
      if (tempoContainerRef.current && !tempoContainerRef.current.contains(event.target)) {
        setShowDetails(false);
      }
      if (accuracyContainerRef.current && !accuracyContainerRef.current.contains(event.target)) {
        setShowAccuracyDetails(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tempoContainerRef, accuracyContainerRef, setShowDetails]); // Only re-run if detailsRef or setShowDetails changes
  

  const navigate = useNavigate();

  let accuracyColor
  if (accuracyPercent == null) {
    accuracyColor = accuracyColors[0]
  } else if (accuracyPercent >= 95) {
    accuracyColor = accuracyColors[1]
  } else if (accuracyPercent >= 75) {
    accuracyColor = accuracyColors[2]
  } else {
    accuracyColor = accuracyColors[3]
  }

  const headerStyle = {
    transform: headerVisible ? 'translateY(0)' : 'translateY(-110px)', // Slide up by full header height
    transition: 'transform 0.5s ease-in-out',
    position: 'fixed',
    width: '100%',
    zIndex: 10, // Ensure header is above other content
    // Other existing styles...
  };

  return (
    <Box sx={{...headerStyle, height: '110px', display: 'flex', position: 'relative'}}>
      <Box sx={{
        background: '#040B13',
        width:'100%', display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent: 'space-between'
        }}>
          <Box sx={{position: 'relative', marginLeft: '30px', display:'flex', flexDirection:'column',alignItems:'center'}}>
            <Box sx={{display:'flex', flexDirection:'row',alignItems:'center'}}>
            <Box 
              sx={backcontainerStyles} 
              onClick={() => navigate('/repertoire')}
            >
              <img style={{height: '100%'}} src={RepBackArrow}/>
            </Box>
          </Box>
          </Box>

        <Box sx={{display: 'flex', alignItems: 'center',}}>

          <Box sx={{display: 'flex', height: '100%', alignItems: 'center',justifyContent: 'center', gap: '16px'}}>
            {/* <Box sx={controlsStyles}> */}
            <Box>
              <Box sx={{position: 'relative'}}>
                { showDetails && <TempoContainer tempo={tempo} setShowDetails={setShowDetails} tempoContainerRef={tempoContainerRef} sliderValue={sliderValue} setSliderValue={setSliderValue} tempPctAdjustment={tempPctAdjustment} setTempPctAdjustment={setTempPctAdjustment} performanceTempo={performanceTempo} manualTempoInput={manualTempoInput} setManualTempoInput={setManualTempoInput} setManualTempoChange={setManualTempoChange} mapTempoToSlider={mapTempoToSlider} tempoInputRef={tempoInputRef} max_tempo_value={max_tempo_value} min_tempo_value={min_tempo_value}/>}
              </Box>
              <Box sx={controlsStyles}>
                <img style={controlsImagesStyles} src={TempoIcon} onClick={() => setShowDetails(!showDetails)}/>
              </Box>
            </Box>

            <Box>
              <Box sx={{position: 'relative'}}>
                  { showAccuracyDetails && <AccuracyContainer tempo={tempo} setShowAccuracyDetails={setShowAccuracyDetails} accuracyContainerRef={accuracyContainerRef} sliderValue={sliderValue} setSliderValue={setSliderValue} tempPctAdjustment={tempPctAdjustment} setTempPctAdjustment={setTempPctAdjustment} performanceTempo={performanceTempo} errorRecognitionActive={errorRecognitionActive} setErrorRecognitionActive={setErrorRecognitionActive}/>}
              </Box>
              <Box sx={controlsStyles}>
                <img style={controlsImagesStyles} src={AccuracyMusicIcon} onClick={() => setShowAccuracyDetails(!showAccuracyDetails)}/>
              </Box>
            </Box>
            {/* <Box sx={{...controlsStyles, border: showCursorSelector ? '4px solid green' : '4px solid #525969'}}>
            <img 
              style={{...controlsImagesStyles}} 
              src={LoopIcon} 
              onClick={() => setShowCursorSelector(!showCursorSelector)}
            />
            </Box> */}
            <Box sx={{height: '60px', marginLeft: '16px', marginRight: '16px', borderRight: '2px solid #525969'}}/>
            <Box sx={{display: 'flex', alignItems: 'center',justifyContent: 'center', gap: '24px'}}>
              {/* <Box sx={{...controlsStyles2, boxSizing: 'content-box', border: inPerformanceMode ? '4px solid green' : 'none'}}> */}
              <Box sx={{...controlsStyles2, 
                // boxSizing: 'content-box', 
                // border: inPerformanceMode ? '4px solid green' : 'none'
                background: inPerformanceMode ? 'rgba(160,255,118,.5)':'unset',
                boxShadow: inPerformanceMode ? '0px 0px 5px 5px rgb(160,255,118,.5)':'unset',
                }}>
                <img style={{maxHeight: '32px'}} src={PerformanceIcon} onClick={() => togglePerformanceMode()}/>
              </Box>
              {/* <Box>
                <img style={{maxHeight: '32px'}} src={SettingsIcon}/>
              </Box> */}
            </Box>
            <Box sx={{height: '60px', marginLeft: '16px', marginRight: '16px', borderRight: '2px solid #525969'}}/>
          </Box>
      
          <Box sx={{
            display:'flex', position: 'relative', border: '2px solid #40E16D', //width: '210px', 
            height: '50px', borderRadius: '30px',
            alignItems: 'center', marginRight: '20px',
            }}>
            <Box sx={{...accuracyStyles, marginLeft: '18px',}}>ACCURACY</Box>
            { true ?//showAccuracy ?
              <Box sx={{...accuracyStyles, width: '90px', marginLeft: '8px', marginBottom: '1px', marginRight: '18px', fontSize: '22px', color: accuracyColor}}>{accuracyPercent != null ? `${accuracyPercent?.toFixed(1)}%` : '--'}</Box>
            :
              <Box sx={{...accuracyStyles, width: '90px', marginLeft: '8px', marginRight: '18px', fontSize: '22px'}}>{'--'}</Box>
            }
          </Box>

        </Box>
      </Box>
    </Box>
  )
}


interface ErrorRecognitionState {
  left: boolean;
  right: boolean;
}

const AccuracyContainer = (props: any) => {
  const { 
    accuracyContainerRef,
    errorRecognitionActive, setErrorRecognitionActive,
  } = props 

  const topPos = 0//getPosition().y
  const leftPos = 0// getPosition().x

  const modalPosition = {
    left: `${leftPos-32}px`,
    top: `${topPos+52}px`,
  };

  return (
    <Box sx={{
      position: 'absolute',
      zIndex: 10,
      ...modalPosition,
      minWidth: '350px',
      borderRadius: '8px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
      padding: '16px',
      backgroundColor: '#050B13',
    }} ref={accuracyContainerRef}>
      <Box sx={{
        position: 'absolute',
        top: '-10px', // Half of the carot size to move it above the modal
        left: '40px', // Align this with the hamburger icon
        width: '0',
        height: '0',
        borderLeft: '16px solid transparent',
        borderRight: '16px solid transparent',
        borderBottom: '16px solid #050B13', // Carot color should match modal background
      }} />
      <Box sx={detailsTitleTextStyles}>
        <Box sx={{textAlign: 'center', marginTop: '6px', marginBottom: '16px', fontSize: '20px', position: 'relative'}}>
          ACCURACY
        </Box>
        <Box sx={{textAlign: 'center', marginTop: '32px', marginBottom: '8px', display: 'flex', justifyContent: 'center', }}>
          <Box sx={{textAlign: 'center', display: 'flex', alignItems: 'center', gap: '7px', fontSize: '18px', color: '#CED0D4', fontWeight: 'bold'}}>
            Show Feedback
          </Box>
        </Box>
        <Box sx={{textAlign: 'center', marginTop: '30px', justifyContent: 'center', display: 'flex', gap: '12px'}}>
          <Box sx={{'&:hover': {cursor: 'pointer'}}} onClick={() => setErrorRecognitionActive((prevState: ErrorRecognitionState) => ({ ...prevState, left: !prevState.left }))}>
            <img 
              src={errorRecognitionActive.left ? HandActive : HandInactive} 
              style={{
                maxHeight: '76px', 
                transform: errorRecognitionActive.left ? 'none' : 'scaleX(-1)'
              }}
            />
          </Box>
          <Box sx={{'&:hover': {cursor: 'pointer'}}} onClick={() => setErrorRecognitionActive((prevState: ErrorRecognitionState) => ({ ...prevState, right: !prevState.right }))}>
            <img 
              src={errorRecognitionActive.right ? HandActive : HandInactive} 
              style={{
                maxHeight: '76px',
                transform: errorRecognitionActive.right ? 'scaleX(-1)' : 'none'
              }}/>
          </Box>
        </Box>
        <Box sx={{display: 'flex', borderBottom: '1px solid #31353F', padding: '20px 14px', margin: '2px 0px'}}></Box>
      </Box>
    </Box>
  )
}

const TempoContainer = (props: any) => {
  const { 
    setShowDetails,
    tempo,
    performanceTempo, 
    tempoContainerRef,
    sliderValue, setSliderValue, tempPctAdjustment, setTempPctAdjustment,
    manualTempoInput, setManualTempoInput, setManualTempoChange, mapTempoToSlider, tempoInputRef, max_tempo_value, min_tempo_value
  } = props 
  const dispatch = useDispatch();

  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const auth = useSelector((state: AuthReducer) => state.authReducer)
  const { userData } = data
  
  const topPos = 0//getPosition().y
  const leftPos = 0// getPosition().x

  const modalPosition = {
    left: `${leftPos-32}px`, // Adjust as needed
    top: `${topPos+52}px`, // Adjust so that it appears below the hamburger icon
  };

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    let actualValue;
    if (Array.isArray(newValue)) { newValue = newValue[0]; }
    if (newValue <= 50) {
      actualValue = 10 + (90 * (newValue / (100/2)));
    } else {
      actualValue = 100 + (2*100 * ((newValue - 100/2) / (100/2)));  // Linearly map to 100-300
    }
    setSliderValue(newValue)
    setTempPctAdjustment(Math.round(actualValue))
  };
  
  const resetTemp = () => {
    dispatch(appActions.setTempo({tempo: performanceTempo}))
    setTempPctAdjustment(100)
    setSliderValue(50)
  }

  const incTemp = () => {
    if (tempo < max_tempo_value) { // Doesn't work, needs to be based on the value at the min and max percentages..
      mapTempoToSlider(tempo+1)
    }
  }

  const decTemp = () => {
    console.log("dc?", tempo, min_tempo_value)
    if (tempo > min_tempo_value) {
      mapTempoToSlider(tempo-1)
    }
  }

  const handleManualTempoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    setManualTempoInput(value)
  };

  // const setManualTempoChange = () => {
  //   let value = parseInt(manualTempoInput, 10)
  //   if (!isNaN(value)) {
  //     mapTempoToSlider(value);
  //   } else {
  //     setManualTempoInput(tempo.toString())
  //   }
  // }

  useEffect(() => {
    setManualTempoInput(tempo.toString())
  }, [tempo])
  
  // const mapTempoToSlider = (newTempo: number) => {
  //   let newTempPctAdjustment = 100*newTempo/performanceTempo // DO not round yet otherwise will not trigger change in tempo (since based on percentage). Only round in display.
  //   let newValue = (newTempPctAdjustment - 100)*(100/2)/(2*100) + 100/2
  //   if (newValue <= 50) {
  //     newValue = ((newTempPctAdjustment - 10)/90)*(100/2)
  //   }
  //   setSliderValue(newValue)
  //   setTempPctAdjustment(newTempPctAdjustment)
  // }

  return (
    <Box sx={{
      position: 'absolute',
      zIndex: 10,
      ...modalPosition,
      minWidth: '350px',
      borderRadius: '8px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
      padding: '16px',
      backgroundColor: '#050B13',
    }} ref={tempoContainerRef}>
      <Box sx={{
        position: 'absolute',
        top: '-10px', // Half of the carot size to move it above the modal
        left: '40px', // Align this with the hamburger icon
        width: '0',
        height: '0',
        borderLeft: '16px solid transparent',
        borderRight: '16px solid transparent',
        borderBottom: '16px solid #050B13', // Carot color should match modal background
      }} />
      
      <Box sx={detailsTitleTextStyles}>
        
        <Box sx={{textAlign: 'center', marginTop: '6px', marginBottom: '16px', fontSize: '20px', position: 'relative'}}>
          TEMPO
        </Box>

        <Box sx={{textAlign: 'center', marginTop: '30px', marginBottom: '16px', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px',}}>
          <Box sx={{display: 'inline-block', '&:hover': {cursor: 'pointer'}}} onClick={decTemp}>
              <img style={{maxHeight: '28px', verticalAlign: 'top'}} src={TempoMinus}/>
          </Box>
          <Box sx ={{margin: '0px 20px', display: 'flex', fontSize: '24px', alignItems: 'start'}}>
            <Box sx={{marginTop: '-3px', marginRight: '1px'}}>
              {/* {tempo} */}
              <TextField
                inputRef={tempoInputRef}
                type="number"
                // value={tempo}
                value={manualTempoInput}

                onChange={handleManualTempoChange}
                onBlur={setManualTempoChange}
                inputProps={{
                  // min: 0,//10, 
                  // max: 9999999,//300, 
                  style: { textAlign: 'left',  color: '#FFFFFF', fontFamily: 'Lato', fontWeight: 400, fontSize: '24px', lineHeight: '24px', letterSpacing: '1px', height: '0px', margin: 0, paddingLeft: '3px', paddingRight: '3px' } 
                }}
                sx={{ 
                  width: '80px', textAlign: 'center' ,
                  '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                  },
                  '& input[type=number]': {
                    '-moz-appearance': 'textfield',
                  },
                  maxWidth: '54px', marginLeft: 0, padding: 0,
                }}
              />
              </Box> <Box sx={{fontSize: '16px'}}>BPM</Box>
          </Box>
          <Box sx={{display: 'inline-block', '&:hover': {cursor: 'pointer'}}} onClick={incTemp}>
              <img style={{maxHeight: '28px', verticalAlign: 'top'}} src={TempoPlus}/>
          </Box>
        </Box>

        <Box sx={{textAlign: 'center', marginTop: '32px', marginBottom: '8px', display: 'flex', justifyContent: 'center', }}>
          { (tempo >= performanceTempo) ?
            <Box sx={{textAlign: 'center', display: 'flex', alignItems: 'center', gap: '7px'}}>
              <Box style={{width: '15px', height: '15px', borderRadius: '50%', backgroundColor: '#40E16D'}}></Box> At Tempo
            </Box>
          :
            <Box sx={{textAlign: 'center', display: 'flex', alignItems: 'center', gap: '7px'}}>
              <Box style={{width: '15px', height: '15px', borderRadius: '50%', backgroundColor: '#EB3459'}}></Box> Below Tempo
            </Box>
          }
        </Box>

        <Box sx={{textAlign: 'center', marginTop: '6px', marginBottom: '16px', position: 'relative', display: 'flex', flexDirection: 'column'}}>
          <StyledSlider handleSliderChange={handleSliderChange} performanceTempo={performanceTempo} sliderValue={sliderValue} setSliderValue={setSliderValue} tempPctAdjustment={tempPctAdjustment} setTempPctAdjustment={setTempPctAdjustment} />
        </Box>

        <Box sx={{textAlign: 'center', marginTop: '30px', marginBottom: '16px', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '0px',}}>
          <Box sx={{magin: 0, padding: 0, '&:hover': {cursor: 'pointer'}}} onClick={resetTemp}>
            <img style={{width: '32px'}} src={Reset}/>
          </Box>
          Reset
        </Box>

        <Box sx={{display: 'flex', borderBottom: '1px solid #31353F', padding: '20px 14px', margin: '2px 0px'}}></Box>

        <Box sx={{textAlign: 'center', marginTop: '6px', marginBottom: '16px', position: 'relative'}}>
          <CustomSwitch defaultChecked={userData?.rep_metronome_sound}
            onChange={() => {
              dispatch(appActions.SetRepMetronomeSound({repMetronomeSound: !userData?.rep_metronome_sound, authToken: auth.jwtToken}))
            }}
          />
            Metronome Sound
        </Box>

        <Box sx={{textAlign: 'center', marginTop: '6px', marginBottom: '16px', position: 'relative'}}>
          <CustomSwitch defaultChecked={userData?.rep_downbeats_sound}
            onChange={() => {
              console.log("setting as?", userData?.rep_downbeats_sound)
              dispatch(appActions.SetRepDownbeatsSound({repDownbeatsSound: !userData?.rep_downbeats_sound, authToken: auth.jwtToken}))
            }}
          />
          Accent Downbeats
        </Box>
       
      </Box>
    </Box>
  )
}


const controlsStyles = {
  height: '54px', border: '4px solid #525969', borderRadius: '50%',
  '&:hover': {
    background: 'rgba(222,144,118,.5)',
    cursor: 'pointer',
    transition: '0.3s',
    borderRadius:'50%',
    boxShadow: '0px 0px 5px 5px rgb(222,144,118,.5)',
  },
}
const controlsStyles2 = {
  // height: '54px', 
  // border: '4px solid #525969', 
  borderRadius: '50%',
  '&:hover': {
    background: 'rgba(222,144,118,.5)',
    cursor: 'pointer',
    transition: '0.3s',
    borderRadius:'50%',
    boxShadow: '0px 0px 5px 5px rgb(222,144,118,.5)',
  },
}
const controlsImagesStyles = {
  marginTop: '-1px', maxHeight: '54px',
}

const accuracyStyles = {
  color: '#ECECEC',
  fontFamily: 'Lato',
  fontSize: '17px',
  fontWeight: '700',
  lineHeight: '20px',
  letterSpacing: '1px',
  textAlign: 'left',
}

const songTitleStyles = {
  fontFamily: 'Lato',
  fontSize: '44px',
  fontWeight: '700',
  lineHeight: '20px',
  letterSpacing: '1px',
  textAlign: 'center',//'left',
}
const composerStyles = {
  fontFamily: 'Lato',
  fontSize: '17px',
  fontWeight: '700',
  lineHeight: '20px',
  letterSpacing: '1px',
  textAlign: 'right',//'left',
}

const backcontainerStyles = {
  marginRight: '18px', 
  height:'25px',
  '&:hover': {
    background: 'rgba(222,144,118,.5)',
    cursor: 'pointer',
    transition: '0.3s',
    borderRadius:'50%',
    boxShadow: '0px 0px 5px 5px rgb(222,144,118,.5)',
  },
}


type FooterContainerProps = {
  setCursorIsVisible: (cursorIsVisible: boolean) => void;
  cursorIsVisible: boolean;
  headerVisible: boolean;
}

const FooterContainer = (props: FooterContainerProps) => {
  const { headerVisible } = props
  const dispatch = useDispatch()
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const repertoireReducerData = useSelector((state: RepertoireReducer) => state.repertoireReducer)
  const { repertoireEnded } = repertoireReducerData

  const playLevel = () => {
    if (repertoireEnded) {
      window.location.reload()
    } else {
      dispatch(appActions.setLessonPlaying({lessonPlaying: !data.lessonPlaying}))
    } 
  }

  return (
    <Box sx={{position: 'fixed', bottom: '0px', left: '0px', width: '100vw', display: 'flex', height: '100px', minHeight: '100px', justifyContent:'space-between', backgroundColor: 'rgba(255, 0, 255, 0)',
    transform: headerVisible ? 'translateY(0)' : 'translateY(140px)', // Slide up by full header height
    transition: 'transform 0.5s ease-in-out',
    }}>
      <Box sx={{zIndex: 1, position: 'absolute', top: 34, left: 30}}>
        <Fullscreen show_label={false} use_dark_icon={true}/>
      </Box>
      { (!repertoireEnded) && 
        <Box onClick={() => window.location.reload()}>
          <Box sx={restartButtonStyles}>
          <img style={{maxWidth: '100%'}} src={Restart}/>
          </Box>
        </Box>
      }
      <Box onClick={() => playLevel()}>
        <Box sx={playButtonStyles}>
          { data.lessonPlaying ?
            <img style={{maxWidth: '100%', maxHeight: '100%', margin: '0px'}} src={Pause}/>
          :
            <>
              { (repertoireEnded) ?
                <img style={{maxWidth: '100%', maxHeight: '100%', margin: '0px'}} src={RestartBig}/>
              :
                <img style={{maxWidth: '100%', maxHeight: '100%', margin: '0px'}} src={Play}/>
              }
            </>
          }
        </Box>
      </Box>
    </Box>
  )
}

const playButtonStyles = {
  position: 'absolute',
  right: '40px',
  bottom: '30px',
  width: '105px',
  height: '105px',
  padding: '0px', margin: '0px',
  borderRadius: '50%',
  '&:hover': {
    background: 'rgba(212,134,108,1.0)',
    cursor: 'pointer',
    transition: '0.3s',
    boxShadow: '0px 0px 5px 5px rgb(212,134,108,.5)',
  },
}

const restartButtonStyles = {
  position: 'absolute',
  right: '160px',
  bottom: '43px',
  width: '75px',
  height: '75px',
  padding: '0px', margin: '0px',
  borderRadius: '50%',
  '&:hover': {
    // background: 'rgba(212,134,108,1.0)',
    cursor: 'pointer',
    transition: '0.3s',
    boxShadow: '0px 0px 5px 5px rgb(196,198,202,.5)',
  },
}

export default RepertoirePlay




const detailsTitleTextStyles = {
  color: '#FFFFFF',
  fontFamily: 'Lato',
  fontSize: '15px',
  fontHeight: '700',
  lineHeight: '24px',
  letterSpacing: '1px',
  textAlign: 'left',
}

const detailsTextStyles = {
  display: 'flex', 
  flexDirection: 'column',
  color: '#ECECEC',
  position: 'relative',
  fontFamily: 'Lato',
  fontSize: '14px',
  fontHeight: '400',
  lineHeight: '16.8px',
  letterSpacing: '1px',
  textAlign: 'left',
}
