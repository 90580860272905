import { Box } from '@mui/material';
import RepertoireItem from './RepertoireItem';
import { useSelector } from 'react-redux'
import { RepertoireReducer } from 'Types/RepertoireTypes';
import { MainAppReducer } from 'Types';
import { useRef } from 'react';
import { DragScroll } from './DragScroll'

const Browse = () => {
  const appReducerData = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const userLevelData = appReducerData.currentUserLevelData
  const highestLevelCompleted = appReducerData.highestLevelCompleted

  const repertoireReducerData = useSelector((state: RepertoireReducer) => state.repertoireReducer)
  const repertoireData = repertoireReducerData.repertoireData
  const containerRef: any = useRef(null)

  let repsByLevels: any = []
  for (let i = 0; i <= highestLevelCompleted; i++) {
    repsByLevels.push([])
  }
  let futureRep:any = {}
  repertoireData.forEach((rep) => {
    if (rep.level.level_number <= highestLevelCompleted) {
      repsByLevels[rep.level.level_number-1].push(rep)
    } else {
      if (futureRep[rep.level.level_number]) {
        futureRep[rep.level.level_number].push(rep)
      } else {
        futureRep[rep.level.level_number] = [rep]
      }
    }
  })
  repsByLevels = repsByLevels.filter((rep: any) => rep.length > 0).reverse()

  return (
    <Box sx={{margin: '0px 0px', justifyContent: 'flex-start',
    height: 'calc(100vh - 60px)', overflowY: 'auto'
  }} ref={containerRef}>
      { repsByLevels.length > 0 &&
        <>
        <Box sx={recForYouStyles}>
          Songs At Your Level
        </Box>
        <Box sx={{margin: '0px 28px', paddingTop: '20px'}}>
          {
          repsByLevels.map((repsByLevel:any, idx:any) => {
            return <RepertoireByLevel repsByLevel={repsByLevel} level_number={repsByLevel[0].level.level_number} containerRef={containerRef}/>
          })
          }
        </Box>
        </>
      }

      { Object.keys(futureRep).length > 0 &&
        <>
        <Box 
          sx = {{
            padding: '24px 28px',
            // backgroundColor: '#171F31',
            color: '#ADB1B7',
            textAlign: 'left',
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '18px',
            borderBottom: '1px solid #525969'
          }}
          // sx={recForYouStyles}
        >
          Songs Above Your Level
        </Box>

        <Box sx={{margin: '0px 28px', paddingTop: '20px'}}>
          {
            Object.keys(futureRep).map((k:any, idx:any) => {
              console.log("ok?", futureRep[k])
              return <RepertoireByLevel repsByLevel={futureRep[k]} level_number={k} hideTitle={true} containerRef={containerRef}/>
            })
          }
        </Box>
        </>
      }
      <Box sx={{height: '360px'}}/>
    </Box>
  )
}
export default Browse

const RepertoireByLevel = (props: any) => {
  const { 
    repsByLevel,
    level_number,
    hideTitle,
    containerRef,
  } = props

  if (!repsByLevel || repsByLevel.length == 0) {
    return (<></>)
  }
  
  return (
    <Box sx={containerStyles}>
      <Box sx={titleStyles}>Level {level_number}</Box> 
      <DragScroll>
        {repsByLevel.map((rep:any) =>
        <RepertoireItem repertoireGroup={'recent-played'} repertoireData={rep} containerRef={containerRef}/>
        )}
      </DragScroll>
    </Box>
  )
}

const containerStyles = {
  margin: '4px 0px'
}

const titleStyles = {
  textAlign: 'left',
  fontFamily: 'Lato',
  color: '#ECECEC',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '15px',
  lineHeight: '24px',
}

const recForYouStyles = {
  padding: '24px 28px',
  backgroundColor: '#171F31',
  color: '#ADB1B7',
  textAlign: 'left',
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '18px',
}
