import { RepertoireActions, RepertoireState, actionTypes } from 'Types/RepertoireTypes'

export const defaultState: RepertoireState = {
  repertoireData: [],
  userRepertoireProgresses: [],
  repertoireIdSelect: 1,
  repertoirePlaying: false,
  repertoireEnded: false,
}

export const repertoireReducer = (state = defaultState, action: RepertoireActions) => {
  switch (action.type) {

    case actionTypes.LOAD_REPERTOIRE_STATE:
      return {
        ...state,
        ...action.payload
      }
    
    case actionTypes.FETCH_REPERTOIRE_DATA_SUCCESS:
      return {
        ...state,
        repertoireData: action.payload
      }

    case actionTypes.SET_REPERTOIRE_ENDED:
      return {
        ...state,
        repertoireEnded: action.payload
      }
    
    case actionTypes.SET_REPERTOIRE_SELECT:
      return {
        ...state,
        repertoireIdSelect: action.payload,
        // currentUserLevelData: find(state.userLevelData, uld => uld.level.level_number === state.levelData[action.payload.levelSelect].level_number),
        // currentLevelTiers: find(state.tiersByLevels, tbl => tbl.level_number === state.levelData[action.payload.levelSelect].level_number)?.tiers
      }

    case actionTypes.FETCH_URP_SUCCESS:
      let current_urp = state.userRepertoireProgresses;
      let updatedArray = current_urp.slice();
      // This is used to "merge" the single-value array that gets passed with the put
      action.payload.forEach(payload_urp => {
        const index = updatedArray.findIndex(state_urp => state_urp.id === payload_urp.id);
        if (index !== -1) {
          updatedArray[index] = payload_urp;
        } else {
          updatedArray.push(payload_urp);
        }
      });

      return {
        ...state,
        userRepertoireProgresses: updatedArray
      }

  default: return state
  }
}
