import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import RootApp from './App';
import reportWebVitals from './reportWebVitals';
import { routerMiddleware, ConnectedRouter } from "connected-react-router"
import { createBrowserHistory } from "history";
import { createStore, applyMiddleware } from "redux"
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from "redux-devtools-extension"
import createRootReducer from "./Reducers"
import rootSaga from './Sagas'
import eventSafa from './Sagas/Events'
import { loadState, saveState } from './Utils/LocalStorage'
import { Provider } from "react-redux"
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { AuthenticationProvider } from 'Contexts/Authentication';
// import { RepertoirePlayProvider } from 'Contexts/RepertoirePlayContext';
import { MidiProvider } from 'Contexts/MidiContext';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AWSRumProvider } from 'Contexts/AWSRum';
const theme = createTheme({      
  typography: {
    button: {
      textTransform: 'none'
    }
  }
})

const rootNode = document.getElementById('root')
const sagaMiddleware = createSagaMiddleware()
const persistedState = {
  mainAppReducer: loadState(null)// (No user id yet, will just load default state!)
}

const history = createBrowserHistory()

const store = createStore(
  createRootReducer(history),
  persistedState,
  composeWithDevTools(
    applyMiddleware(sagaMiddleware, routerMiddleware(history))
  )
)

sagaMiddleware.run(rootSaga)

ReactDOM.render(
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AuthenticationProvider>
        {/* <RepertoirePlayProvider> */}
          {/* <MidiProvider location={location} navigate={navigate}> */}
          <AWSRumProvider>
          <ConnectedRouter history={history}>
            <RootApp store={store}/>
          </ConnectedRouter>
            </AWSRumProvider>
          {/* </MidiProvider> */}
          {/* </RepertoirePlayProvider> */}
        </AuthenticationProvider>
      </Provider>
    </ThemeProvider>, rootNode
);


// export default ReactMusicXML;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
