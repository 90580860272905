import { LevelData } from "Types";
export type UserRepertoireData = {
  
}


export type Genre = {
  id: number;
  name: string;
}
export type Composer = {
  id: number;
  name: string;
}

export type RepertoireData = {
  id: number;
  name: string;
  bpm: number;
  level: LevelData;
  genres: Genre[];
  composers: Composer[];
}

export type RepertoireDataId = {
  id: number;
}

export type UserRepertoireProgress = {
  id?: number;
  user_id?: string;
  status: string;
  repertoire: RepertoireDataId;
  last_played_at: string;
  completed_at: string | null;
  accuracy: number | null;
  percent_complete: number | null;
}

export interface SetRepertoireSelectPayload {
  repertoireIdSelect: number;
}


export enum actionTypes {
  UPDATE_REPERTOIRE_DATA_REQUEST = "UPDATE_REPERTOIRE_DATA_REQUEST",
  FETCH_REPERTOIRE_DATA_SUCCESS = "FETCH_REPERTOIRE_DATA_SUCCESS",
  SET_REPERTOIRE_ENDED = "SET_REPERTOIRE_ENDED",
  SET_REPERTOIRE_SELECT = "SET_REPERTOIRE_SELECT",
  FETCH_URP_SUCCESS = "FETCH_URP_SUCCESS",
  LOAD_REPERTOIRE_STATE = "LOAD_REPERTOIRE_STATE",
}


export enum REPERTOIRE_PROGRESS {
  COMPLETE = "complete",
  IN_PROGRESS = "in progress",
  NOT_STARTED = "not started",
}

export interface RepertoireState {
  repertoireData: RepertoireData[],
  userRepertoireProgresses: UserRepertoireProgress[],
  repertoireIdSelect: number;
  repertoirePlaying: boolean;
  repertoireEnded: boolean;
}

export type UpdateRepertoireDataRequest = {
  type: typeof actionTypes.UPDATE_REPERTOIRE_DATA_REQUEST;
  payload: RepertoireData
}

export type FetchRepertoireDataSuccess = {
  type: typeof actionTypes.FETCH_REPERTOIRE_DATA_SUCCESS;
  payload: RepertoireData
}

export type SetRepertoireEnded = {
  type: typeof actionTypes.SET_REPERTOIRE_ENDED;
  payload: boolean;
}

export type SetRepertoireSelect = {
  type: typeof actionTypes.SET_REPERTOIRE_SELECT;
  payload: SetRepertoireSelectPayload;
}

export type FetchURPSuccess = {
  type: typeof actionTypes.FETCH_URP_SUCCESS;
  payload: UserRepertoireProgress[]
}

export type LoadRepertoireState = {
  type: typeof actionTypes.LOAD_REPERTOIRE_STATE;
  payload: RepertoireData
}


export interface RepertoireReducer {
  repertoireReducer: RepertoireState,
}

export type RepertoireActions =
  | UpdateRepertoireDataRequest
  | FetchRepertoireDataSuccess
  | FetchURPSuccess
  | SetRepertoireEnded
  | SetRepertoireSelect
  | LoadRepertoireState