import { Box } from "@mui/system"
import { useRef, useState, useEffect, ReactNode } from "react";

import { createContext, useContext } from 'react';

const PreventRepClickContext = createContext(false);
export const usePreventRepClick = () => useContext(PreventRepClickContext);

interface DragScrollProps {
  children: ReactNode;
}

export const DragScroll: React.FC<DragScrollProps> = ({ children }) => {
  const containerRef: any = useRef(null)
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [preventRepClick, setPreventRepClick] = useState(false);

  const handleMouseDown = (e: any) => {
    setIsDragging(true);
    setStartX(e.clientX);
  };
  const handleMouseMove = (e: any) => {
    if (isDragging) {
      setPreventRepClick(true); // Once dragging, will disable clicking repertoire from firing
      const dx = startX - e.clientX;
      containerRef.current.scrollLeft += dx;
      setStartX(e.clientX);
    }
  };
  const handleMouseUp = () => {
    setIsDragging(false);
    setTimeout(() => {
      setPreventRepClick(false)
    }, 250)
  };

  useEffect(() => {
    if (containerRef.current) {
      const handleDragStart = (e: any) => e.preventDefault();
      containerRef.current.addEventListener("dragstart", handleDragStart);
      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("dragstart", handleDragStart);
        }
      };
    }
  }, []);

  return (
    <PreventRepClickContext.Provider value={preventRepClick}>
      <Box sx={{display: 'flex', gap: '14px', overflowX: 'auto'}}
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        >
        {children}
      </Box>
    </PreventRepClickContext.Provider>
  )
}
