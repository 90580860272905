import { useState, useCallback, useRef } from 'react'
import { Button, Box, TextField, Link, FormGroup, FormControlLabel, Checkbox, Input } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import './trial_welcome.css'
import IntroFlowBack from 'Components/IntroFlowBack';
import WelcomeLink from './WelcomeLink';
import WelcomeButton from './WelcomeButton';
import Logo from 'Components/Logo';
import { planPricing, discountPercentages, stripePriceIds, numTrialDays } from 'Billing/Constants';
import { useAuthenticationContext } from 'Contexts/Authentication';
import axios, { AxiosError, AxiosResponse } from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import * as appActions from '../../Actions/app'
import { MainAppReducer } from 'Types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AuthReducer } from 'Types/AuthTypes';

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/`

// Backend endpoint validates backend hard coded list

const TrialWelcome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { user, signOut } = useAuthenticationContext();
  const [showDiscountCode, setShowDiscountCode] = useState(false)
  const [discountCode, setDiscountCode] = useState('')
  const [discountCodeValid, setDiscountCodeValid] = useState<Boolean | null>(null)
  const [isLoading, setIsLoading] = useState(false);
  const auth = useSelector((state: AuthReducer) => state.authReducer)

  const authToken = auth.jwtToken
  const userData = useSelector((state: MainAppReducer) => state.mainAppReducer.userData)
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const validateDiscountCode = async () => {
    try {
      const response = await axios.post<any>(
        `${baseUrl}stripe/validate-coupon-code`,
        { code: discountCode },
        { headers: {'Authorization': `Bearer ${authToken}`}}
      )
      setDiscountCodeValid(true)
    } catch {
      setDiscountCodeValid(false)
    }
  }

  const handleSubmit = useCallback(async () => {
    if (isLoading) return
    // user details from context:
    const userFullName = user?.attributes?.name
    const userEmail = user?.attributes?.email
    const userPhoneNumber = user?.attributes?.phone_number
    setIsLoading(true)
    // This will start the free trial:
    try {
      const response = await axios.post<any>(
        `${baseUrl}stripe/create-customer-and-subscription`,
        { 
          priceId: stripePriceIds.monthly,
          userFullName: userFullName,
          userEmail: userEmail,
          userPhoneNumber: userPhoneNumber,
          code: discountCodeValid ? discountCode : null, // Only pass coupon if valid (otherwise validator will fail it.)
        },
        {
          headers: {'Authorization': `Bearer ${authToken}`}
        }
      );
      const data = response.data
      dispatch(appActions.setUserData({
        stripe_customer_id: data.customer.id,
        subscription_status: data.subscription.status,
        coupon_code: discountCode,
        audio_on: !! userData?.audio_on,
        rep_metronome_sound: true, // Set both as true defaults (also defualt true in DB)
        rep_downbeats_sound: true, // Set both as true defaults (also defualt true in DB)
        last_shown_survey: userData?.last_shown_survey,
      }))
      if (process.env.REACT_APP_NODE_ENV == 'prd' && window.gtag) {
        // Event snippet for Begin Free Trial conversion (Google Ads)
        window.gtag('event', 'conversion', { 'send_to': 'AW-11476609062/V4t3CKjdiJcZEKbQvOAq' });
      }
      navigate('/roadmap')
      
    } catch (err) {
      setIsLoading(false)
      console.error(err);
    }
  }, [discountCode, discountCodeValid, dispatch, isLoading, navigate, user?.attributes?.email, user?.attributes?.name, user?.attributes?.sub, auth?.jwtToken])

  useEffect(() => {
    // redirect user back to roadmap so they can't access trial again and do something weird
    if (userData && userData.subscription_status != null && userData.subscription_status != "") {
      if (!isLoading) { // ensure that redirect doesn't happen to roadmap after submit! (inital state will not be loading)
        navigate('/roadmap')
      }
    }
  }, [userData])

  let inputClass
  if (discountCodeValid) {
    inputClass = 'discount-input valid-discount'
  } else if (discountCodeValid == false) {
    inputClass = 'discount-input invalid-discount'
  } else {
    inputClass = 'discount-input discount-input-neutral'
  }

  return (
    <Box className="page-div-styles">
    <Box className="filter-div-styles">
    <Box className="sign-in-div-styles">
      <IntroFlowBack/>
      <Box className="trial-logout-container" onClick={signOut}>
        Log out
      </Box>
      <Logo/>
      <Box className="plan-scroll-container">
        <Box className="desc-container">
        <h3 style={{marginTop: '50px', marginBottom: 0}}>How your free trial works</h3>
        <p style={{padding: '0px 20px 0px 20px', lineHeight: '150%'}}>If you don't have a <Link href="https://museflow.ai/faq" target="_blank" className="link">MIDI keyboard</Link> yet, no worries. You'll have {numTrialDays} days free to get set up before committing to a payment plan.</p>
        </Box>
        <Box className="plan-container">
          <Box className="plan">
            <Box className="plan-desc">
              {discountCodeValid &&
                <Box className="discount-percent">
                  <p className="discount-percent-text">
                    {`${discountPercentages.monthly.withCode}% off`}
                  </p>
                </Box>
              }
              <p className="plan-text-desc">Monthly Plan</p>
              <p className="plan-text-price">{`$${discountCodeValid ? planPricing.monthly.withCode : planPricing.monthly.regular}`}</p>
              <p className="plan-text-time">per month</p>
            </Box>
          </Box>
          <Box className="plan">
            <Box className="plan-desc">
              <Box className="discount-percent">
                <p className="discount-percent-text">
                  {`${discountCodeValid ? discountPercentages.annually.withCode : discountPercentages.annually.regular}% off`}
                </p>
              </Box>
              <p className="plan-text-desc">Annual Plan</p>
              <p className="plan-text-price">{`$${discountCodeValid ? planPricing.annually.withCode : planPricing.annually.regular}`}</p>
              <p className="plan-text-time">per year</p>
            </Box>
          </Box>
        </Box>
        
        <Box className="submit-container">
            <WelcomeButton sx={{width: '315px', height: '50px'}} onClick={() => handleSubmit()}>
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                `START ${numTrialDays}-DAY FREE TRIAL`
              )}
            </WelcomeButton>
          { (!showDiscountCode) &&
          <Button onClick={() => {
            setShowDiscountCode(true);
            setTimeout(() => {
              submitButtonRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 150) //timeout to let button component render
          }} className="discount-code">Got a discount code?</Button>
          }
        </Box>
        {showDiscountCode &&
          <Box className="discount-container">
            <Box className="code-field-container">
              <TextField
                className={inputClass}
                label="Enter Discount Code"
                value={discountCode}
                onChange={e => {
                  setDiscountCode(e.target.value)
                }}
              />

              {discountCodeValid ? 
                <Box className="code-container">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                    <path d="M6 1.37158C3.25 1.37158 1 3.62158 1 6.37158C1 9.12158 3.25 11.3716 6 11.3716C8.75 11.3716 11 9.12158 11 6.37158C11 3.62158 8.75 1.37158 6 1.37158ZM5 8.87158L2.5 6.37158L3.205 5.66658L5 7.45658L8.795 3.66158L9.5 4.37158L5 8.87158Z" fill="#2E5B3E"/>
                  </svg>
                  CODE APPLIED
                </Box>
                :
                ((discountCodeValid == false) &&
                  <Box className="code-container invalid">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                      <path d="M6.5 6.87158H5.5V3.87158H6.5M6.5 8.87158H5.5V7.87158H6.5M6 1.37158C5.34339 1.37158 4.69321 1.50091 4.08658 1.75218C3.47995 2.00346 2.92876 2.37176 2.46447 2.83605C1.52678 3.77373 1 5.0455 1 6.37158C1 7.69766 1.52678 8.96943 2.46447 9.90712C2.92876 10.3714 3.47995 10.7397 4.08658 10.991C4.69321 11.2423 5.34339 11.3716 6 11.3716C7.32608 11.3716 8.59785 10.8448 9.53553 9.90712C10.4732 8.96943 11 7.69766 11 6.37158C11 5.71497 10.8707 5.06479 10.6194 4.45816C10.3681 3.85154 9.99983 3.30034 9.53553 2.83605C9.07124 2.37176 8.52005 2.00346 7.91342 1.75218C7.30679 1.50091 6.65661 1.37158 6 1.37158Z" fill="#B54520"/>
                    </svg>
                    INVALID CODE
                  </Box>
                )
              }
            </Box>
            <Button ref={submitButtonRef} onClick={() => validateDiscountCode()} className="discount-button" sx={{marginBottom: '24px'}} >Submit</Button>
          </Box>
        }
        <Box className="scroll-box-bottom"/>
    </Box>
    </Box>
    </Box>
    </Box>
  )
}

export default TrialWelcome;

